body {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'CoHeadline', sans-serif;
}

.container {
  padding-top: 50px;
}

.btn-primary {
  background-color: #057E80;
  border-color: #057E80;
}

.btn-primary:hover {
  background-color: #0A6265;
  border-color: #0A6265;
}

.form-group {
  margin-bottom: 20px; /* Aumenta o espaço entre os campos */
}

/* Estilo para a imagem de fundo */
.background-image-container {
  position: relative;
  padding: 0; /* Remove padding */
  min-height: 100vh;
}

.background-image {
  background-image: url('https://penaestrada.com.br/wp-content/uploads/2018/06/caminhoes_do_tipo_cegonha_e_tanque_destaque.jpg');
  background-size: cover;
  background-position: 0 100%;
  height: 100%;
  width: 100%;
  transform: scaleX(-1); /* Inverte a imagem horizontalmente */
}

.background-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Máscara preta com opacidade de 70% */
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-content {
  text-align: center;
}

.logo {
  width: 290px; /* Ajuste o tamanho conforme necessário */
  margin-bottom: 20px; /* Espaçamento entre o logo e o texto */
}

.background-text {
  color: white;
  font-size: 2em;
}

.form-container {
  display: flex;
  justify-content: center;
  min-height: 100%;
  align-items: center;
}

.composable-map {
  max-width: 100%;
  height: auto;
}

.map-container {
  width: 100%;
  max-width: 100%;
}

.map-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}

.p-none {
  padding: 0 !important;
}

/* Estilo para o botão com ícone */
.button-group {
  display: flex;
  justify-content: flex-end;
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary .icon {
  margin-left: 5px;
}

div.equipament-container {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  position: relative;
}

div.equipament-container:nth-child(odd) {
  background-color: #f7f7f7;
}

.text-danger {
  color: red;
  font-size: 12px;
  clear: both;
}

.remove-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.btn-check {
  background-color: #CCC;
  padding: 15px;
  width: 100px;
  margin-left: 15px;
  position: relative;
  float: left;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: inherit !important;
}

.btn-check img {
  width: 100%;
}

.btn-check p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.btn-check.selected {
  border: 2px solid #0A6265;
  background-color: #057E80;

  p {
    color: #ffffff;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.mh-100vh {
  min-height: 100vh;
}

.header {
  width: 100%;
  height: 90px;
  display: none;
}

.header .header-image {
  width: 100%;
  height: 90px;
  object-fit: cover;
}

.btn-placa {
  background-color: #CCC;
  width: 100px;
  margin-left: 15px;
  position: relative;
  float: left;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: inherit !important;
}

.btn-placa input {
  text-transform: uppercase;
  font-weight: bold;
  background: #CCC;
  padding: 0px;
}

.btn-outros {
  background-color: #CCC;
  width: 330px;
  margin-left: 15px;
  position: relative;
  float: left;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: inherit !important;
}

.btn-outros input { 
  text-transform: uppercase;
  font-weight: bold;
  background: #CCC;
  padding: 0px;
}

.btn-campo-obrigatorio {
  background-color: #f7f7f7;
  width: 126px;
  position: relative;
  float: left;
  margin-bottom: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: inherit !important;
}

.btn-campo-obrigatorio input {
  text-transform: uppercase;
  font-weight: bold;
  background: #f7f7f7;
  padding: 0px;
}

.uppercase {
  text-transform: uppercase;
}

/* Container para alinhar à esquerda e adaptar o layout */
.container {
  display: flex;
  justify-content: flex-start; /* Alinhado à esquerda */
  padding: 0px;
  font-family: Arial, sans-serif;
}

/* Label para alinhar checkbox e texto */
.label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
}

/* Estilos do checkbox */
.checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid green;
  background-color: white;
  margin-top: 3px; /* Alinha o checkbox ao topo do texto */
  margin-right: 10px;
  border-radius: 3px;
  outline: none;
  position: relative;
  cursor: pointer;
}

/* Estilo do checkbox quando marcado */
.checkbox:checked {
  background-color: white;
  border-color: green;
}

.checkbox:checked::before {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: green;
  font-weight: bold;
  font-size: 1rem;
}

/* Estilo do texto */
.text {
  margin-top: 0px;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8); /* Fundo levemente transparente */
  z-index: 1000; /* Certifica-se de que o spinner esteja acima dos outros componentes */
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.nos-estamos {
  color: #0a6265;
  font-weight: 600;
  font-size: 6vw; 
  font-family: 'Montserrat';
  margin: 0px; 
  padding: 0px;
}

.a-todo-vapor {
  color: #0a6265;
  font-weight: 600; 
  font-size: 6vw; 
  font-family: 'Montserrat'; 
  margin-bottom: 10px;
  padding: 0px;
}

.faltam {
  color: #ffffff; 
  font-weight: 500; 
  font-size: 4vw; 
  font-family: 'Montserrat'; 
  padding: 3px 50px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dias {
  color: #0a6265;
  font-weight: 500; 
  font-size: 7vw;
  font-family: 'Montserrat';
  margin: 0px; 
  padding: 0px;
}

.para-o-lancamento {
  color: #606060; 
  font-weight: 500;
  font-size: 3vw; 
  font-family: 'Montserrat';
  margin: 0px;
  padding: 0px;
}

.aguardem {
  color: #0a6265; 
  font-weight: 600;
  font-size: 7vw; 
  font-family: 'Montserrat';
  margin: 0px; 
  padding: 0px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .background-image-container {
    display: none;
  }

  .header {
    display: block;
  }
}


@media (max-width: 480px) {
  .nos-estamos {
    font-size: 10vw;
  }

  .a-todo-vapor {
    font-size: 10vw;
  }

  .faltam {
    font-size: 8vw;
  }

  .dias {
    font-size: 11vw;
  }

  .para-o-lancamento {
    font-size: 6vw; 
  }

  .aguardem {
    font-size: 11vw; 
  }
}
